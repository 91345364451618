import './_divider.scss'

const Divider = () => {
    return (
        <div className={'divider'}>
            OR
        </div>
    );
};

export default Divider;
const emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const isEmailValid = (data) => {
    let result = true;

    if (!data) {
        result = false;
    }

    if (!emailPattern.test(data)) {
        result = false;
    }

    return result;
}

export const isPasswordValid = (data) => {
    let result = true,
        minLength = 8;

    if (!data || data.length < minLength) {
        result = false;
    }

    return result;
}

export const isUserLoginDataValid = (data) => {
    return isEmailValid(data.email) && isPasswordValid(data.password);
}